<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form
          ref="form"
          inline
          :model="searchData"
          label-width=""
      >
        <el-form-item label="手机号码">
          <el-input
              size="small"
              v-model="searchData.mobile_like"
              placeholder="请输入"
              clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="到期时间">
          <el-date-picker
              v-model="searchData.expire_time_range"
              style="width: 220px"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="-"
              size="mini"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="注册时间">
          <el-date-picker
              v-model="searchData.created_at_range"
              style="width: 220px"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="-"
              size="mini"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="getList"
          >搜索
          </el-button>
          <el-button
              type="info"
              size="mini"
              icon="el-icon-refresh"
              @click="reset"
          >重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
          fixed
          prop="id"
          label="ID"
          width="width"
          min-width="100px"
      >
      </el-table-column>
      <el-table-column
          fixed
          prop="nickname"
          label="昵称"
          width="width"
          min-width="100px"
      >
      </el-table-column>
      <el-table-column
          fixed
          prop="company_name"
          label="企业名称"
          width="width"
          min-width="100px"
      >
      </el-table-column>
      <el-table-column
          fixed
          prop="mobile"
          label="手机号码"
          width="width"
          min-width="120px"
      >
      </el-table-column>
      <!--      <el-table-column-->
      <!--          prop="inviter_user"-->
      <!--          label="邀请人"-->
      <!--          width="width"-->
      <!--          min-width="180px"-->
      <!--      >-->
      <!--        <template slot-scope="{ row }">-->
      <!--          <div v-if="row.invite_user">-->
      <!--            <div>ID: {{ row?.invite_user?.id }}</div>-->
      <!--            <div>昵称: {{ row?.invite_user?.nickname }}</div>-->
      <!--            <div>手机号码: {{ row?.invite_user?.mobile }}</div>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--          prop="user_product"-->
      <!--          label="产品信息"-->
      <!--          width="280px"-->
      <!--          min-width="180px"-->
      <!--      >-->
      <!--        <template slot-scope="{ row }">-->
      <!--          <div v-if="row.user_product">-->
      <!--            <div>类型：{{ row?.user_product?.type_dsc }}</div>-->
      <!--            <div>账号数量：{{ row?.user_product?.account_num }}</div>-->
      <!--            <div>购买次数：{{ row?.user_product?.order_num }}</div>-->
      <!--            <div>到期时间：{{ row?.user_product?.expire_time }}</div>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->

      <!--      <el-table-column-->
      <!--        prop="agent"-->
      <!--        label="代理商"-->
      <!--        width="280px"-->
      <!--        min-width="180px"-->
      <!--      >-->
      <!--        <template slot-scope="{ row }">-->
      <!--          <div v-if="row.agent">-->
      <!--            <div>ID：{{ row?.agent?.id }}</div>-->
      <!--            <div>代理商名称：{{ row?.agent?.name }}</div>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
          prop="last_follow_log_date"
          label="最后跟进信息"
          width="width"
          min-width="250px"
      >
        <template slot-scope="{ row }">
          <div v-if="!row.last_follow_log">暂未跟进</div>
          <div v-else>
            <p>跟进内容：{{ row.last_follow_log.content }}</p>
            <p>跟进日期：{{ row.last_follow_log.created_at }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="expire_date"
          label="到期时间"
          width="width"
          min-width="150px"
      ></el-table-column>
      <el-table-column
          prop="give_count"
          label="赠送次数"
          width="width"
          min-width="70px"
      ></el-table-column>
      <el-table-column
          prop="order_success_count"
          label="会员类型"
          width="width"
          min-width="120px"
      >
        <template slot-scope="{ row }">
          <el-tag type="danger" v-if="row.order_success_count == 0">普通会员</el-tag>
          <el-tag type="success" v-else>付费会员</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="created_at"
          label="创建日期"
          width="width"
          min-width="150px"
      ></el-table-column>
      <el-table-column
          label="操作"
          width="140px"
          fixed="right"
      >
        <template slot-scope="{ row }">
          <el-link
              style="margin-right: 10px"
              type="primary"
              :underline="false"
              @click="handleDetail(row.id)"
          >详情
          </el-link>

          <el-link
              style="margin-right: 10px"
              type="primary"
              :underline="false"
              @click="handleAddFollowLog(row.id)"
          >跟进
          </el-link>

<!--          <el-link-->
<!--              type="primary"-->
<!--              @click="giveUserExpireTime(row)"-->
<!--          >赠送-->
<!--          </el-link>-->
        </template>
      </el-table-column>
    </el-table>
    <pagination
        :total="total"
        :page.sync="searchData.page"
        :limit.sync="searchData.pagesize"
        @pagination="getList"
    />
    <!-- 详情弹窗 -->
    <Detail ref="detail" @modify-success="modifySuccess" v-if="modifySuccess"></Detail>
    <add-follow-log
        v-if="dialogVisible"
        :dialog-visible="dialogVisible"
        @close-form="handleClose"
        @modify-success="modifySuccess"
        :uid="uid"
    ></add-follow-log>
  </div>
</template>

<script>
import {getListAPI, getDetailAPI, followLogAddAPI, giveExpireTimeAPI} from './api'
import {authBtnMixin} from '@/mixins/authBtnMixin'
import Detail from './detail.vue'
import AddFollowLog from './components/addFollowLog.vue'

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: {Detail, AddFollowLog},
  data() {
    return {
      dialogVisible: false,
      rules: [{required: true, message: '该项不能为空', trigger: 'blur'}],
      show_detail: false,
      show_review: false,
      list: [],
      city_list: [],
      uid: 0,
      searchData: {
        page: 1,
        pagesize: 10,
        mobile_like: '',
        agent_name: '',
        created_at_range: [],
        expire_time_range: [],
      },
      id: 0,
      total: 0,
      changeStatusData: {
        id: '',
        status: null
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    giveUserExpireTime({id}) {
      this.$confirm('是否确定赠送三天？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        giveExpireTimeAPI(id, 3).then(() => {
          this.$message.success('提交成功')
          this.orderDialogVisible = false
          this.selectUser = {}
          this.getList()
        })
      })
    },
    handleAddFollowLog(uid) {
      this.dialogVisible = true
      this.uid = uid
    },
    handleClose() {
      this.dialogVisible = false
    },
    modifySuccess() {
      this.dialogVisible = false
      this.getList()
    },
    // 增加追加跟进记录
    async followLogAdd(params) {
      const res = await followLogAddAPI(params)
    },
    // 详情
    async handleDetail(id) {
      this.$refs.detail.id = id
      this.$refs.detail.show_detail = true
    },
    handleClickTab(val) {
      if (this.searchData.pay_status == val) return
      this.searchData.pay_status = val
      this.getList()
    },
    // 审核弹窗
    getStatus(row) {
      this.changeStatusData = {
        id: row.id,
        status: row.status
      }
      this.show_review = true
    },
    changeStatus() {
      changeStatusAPI(this.changeStatusData).then(() => {
        this.$message.success('提交成功')
        this.getList()
        this.show_review = false
      })
    },
    async getList() {
      // 获取get 参数
      let params = {...this.searchData}
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    // del
    async handleDel({id}) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            delAPI(id).then(() => {
              this.$message.success('删除成功')
              this.getList()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
    },
    // 新增
    handleAdd() {
      this.show_update = true
      this.$nextTick(() => {
        this.formData = {
          name: '',
          icon: '',
          describe: ''
        }
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({id}) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.getList()
              this.show_update = false
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.getList()
              this.show_update = false
            })
          }
        }
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;

    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }

    .active {
      color: #409eff;
      font-weight: 600;

      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }

  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }

  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.icon-box /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }

  .avatar {
    width: 108px;
    height: 108px;
    display: block;
  }
}

.user-box {
  .row {
    display: flex;

    span {
      &:nth-child(1) {
        width: 72px;
        text-align: right;
      }

      &:nth-child(2) {
        text-align: left;
      }
    }
  }
}
</style>
